<template>
	<div class="page">
		<div class="bg">
			<div class="content">
				<div class="contentTitle">
					<div class="title">{{info.title}}</div>
					<div class="yellowLine"></div>
					<div class="titTop">{{info.business_time}}</div>
				</div>
				<div class="ribbonBox">
					<div :class="`ribbon ${info.activity_status==1 ? '' : 'grayRibbn' }`">
						{{info.activity_status==1 ? (lang==6?'进行中':'In progress') : (lang==6?'已结束':'END')}}
					</div>
				</div>
				<div class="text" v-html="info.describe"></div>
				<swiper :options="swiperOption" ref="mySwiper">
					<swiper-slide class="swiper-slide" v-for="(item,index) in info.atlas" :key="index">
						<img :src="item" mode="widthFit" class="swpierImg" />
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
				<div class="btn-bg">
					<div class="btn"
						@click="$route.query.type == 1 ? $router.push('/activityGroup'):$router.push('/activityBrand')">
						{{lang==6?'返回活动列表':'< Back to Activity List'}}
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import mixinLang from '../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				info: {},
				// 轮播配置
				swiperOption: {
					slidesPerView: 1,
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true //允许分页点击跳转
					}
				}
			}
		},
		methods: {
			getInfo() {
				this.$api.getActivityDetails({
					id: this.$route.params.id
				}).then(res => {
					res.details.atlas = res.details.atlas.split('|')
					this.info = res.details
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		padding: 180px 0;
		padding-bottom: 300px;
		background-color: #101621;
		position: relative;
	}

	.bg {
		margin: 0 60px;
		padding: 78px 50px;
		background-color: #EEEEEE;
		border-radius: 16px;
	}

	.titleBox {
		margin-bottom: 60px;
		padding: 0 111px 0px;

		.titBottom {
			font-size: 40px;
		}
	}

	.content {
		// margin: 110px;
		border: 2px solid #97233F;
		position: relative;
		padding: 59px;
		padding-bottom: 150px;

		.contentTitle {
			position: relative;
			top: -90px;
		}

		.title {
			background-color: #EEEEEE;
			font-family: Montserrat-Bold, sans-serif;
			font-size: 40px;
			font-weight: bold;
			position: relative;
			left: -30px;
			padding: 0 30px;
			width: auto;
			display: inline-block;
			max-width: 958px;
			color: #97233F;
		}

		.titTop {
			font-family: SourceHanSansCN-Heavy, sans-serif;
			font-size: 36px;
			font-weight: 900;
			color: #B5A191;
			margin-bottom: 0;

			&::after {
				display: none;
			}
		}

		.text {
			font-family: SourceHanSansCN-Normal, sans-serif;
			font-size: 18px;
			font-weight: 350;
			line-height: 36px;
			position: relative;
			top: -40px;
			overflow: hidden;
		}

		.ribbonBox {
			position: absolute;
			right: 40px;
			top: -23px;
			width: 200px;
			height: 46px;
			background-color: #EEEEEE;
		}

		.ribbon {
			width: 185px;
			height: 46px;
			background: url(../../assets/activity/ribbon-red.png) no-repeat top/155px 100%;
			background-color: #EEEEEE;
			color: #FFF;
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 24px;
			font-weight: bold;
			text-align: center;
			line-height: 46px;
			text-transform: uppercase;
		}

		.grayRibbn {
			background: url(../../assets/activity/ribbon-gray.png) no-repeat top/100% 100%;
		}

		.btn-bg {
			background-color: #EEEEEE;
			display: block;
			width: 280px;
			position: absolute;
			bottom: -26px;
			right: 30px;
			height: 46px;
		}

		.btn {
			margin: 0 auto;
			width: 250px;
			height: 46px;
			background-color: #97233F;
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 22px;
			font-weight: bold;
			line-height: 46px;
			text-align: center;
			cursor: pointer;
			color: #FFF;
		}
	}

	.swpierImg {
		width: 100%;
		height: 640px;
	}

	/deep/ .swiper-pagination-bullet-active {
		background-color: #97233F;
	}
</style>